<template>
  <div>
    <div id="knives-partners" class="knives-id"></div>
    <div class="knives__partners">
      <h3 class="knives__partners-title knives__block-title">
        {{ $t("knives.partners") }}
      </h3>
      <intersect @enter="getAnchor('partners')" @leave="getAnchor(null)">
        <div class="knives__partners-slider">
          <swiper ref="knivesPartners" :options="swiperOption">
            <swiper-slide v-for="(item, index) in partners" :key="index">
              <div class="knives__partners-item">
                <img :src="item.image" alt="" />
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </intersect>
      <!-- <marquee behavior="scroll" loop="infinite">
        <div class="knives__partners-slider">
          <div
            v-for="(item, index) in partners"
            :key="index"
            class="knives__partners-item"
          >
            <img :src="item.image" alt="" />
          </div>
        </div>
      </marquee> -->
    </div>
  </div>
</template>

<script>
import Intersect from "vue-intersect";
import { mapActions } from "vuex";
import { actionTypes } from "@/store/modules/knives";
export default {
  name: "KnivesPartners",
  components: {
    Intersect,
  },
  data() {
    return {
      swiperOption: {
        spaceBetween: 50,
        loop: true,
        loopAdditionalSlides: 10,
        centeredSlides: true,
        speed: 1000,
        autoplay: {
          delay: 100,
          disableOnInteraction: false,
        },
        breakpoints: {
          1600: {
            slidesPerView: "auto",
          },
          1200: {
            slidesPerView: 4,
          },
          200: {
            slidesPerView: 3,
            centeredSlides: false,
          },
        },
      },
      partners: [
        {
          image: require("@/" + "assets/img/knives/partners-1.svg"),
        },
        {
          image: require("@/" + "assets/img/knives/partners-2.svg"),
        },

        {
          image: require("@/" + "assets/img/knives/partners-3.svg"),
        },
        {
          image: require("@/" + "assets/img/knives/partners-11.png"),
        },
        {
          image: require("@/" + "assets/img/knives/partners-4.svg"),
        },
        {
          image: require("@/" + "assets/img/knives/partners-5.svg"),
        },
        {
          image: require("@/" + "assets/img/knives/partners-6.png"),
        },
        {
          image: require("@/" + "assets/img/knives/partners-7.svg"),
        },
        {
          image: require("@/" + "assets/img/knives/partners-8.svg"),
        },
        {
          image: require("@/" + "assets/img/knives/partners-9.svg"),
        },
        {
          image: require("@/" + "assets/img/knives/partners-10.svg"),
        },
        {
          image: require("@/" + "assets/img/knives/partners-11.svg"),
        },
      ],
    };
  },
  methods: {
    ...mapActions("knivesModule", {
      getAnchor: actionTypes.getAnchor,
    }),
  },
};
</script>

<style lang="scss" scoped>
// .swiper-wrapper {
//   -webkit-transition-timing-function: linear !important;
//   transition-timing-function: linear !important;
// }

.swiper-slide {
  max-width: max-content;
}

// .knives__partners-slider {
//   display: flex;
//   align-items: center;
//   min-width: 0;
// }

.knives__partners-item {
  padding: 0 10px;
}
</style>
